import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// List of events that will reset the logout timer (e.g., any user activity)
const events = [
  "load",       // When the page is loaded
  "mousemove",  // When the user moves the mouse
  "mousedown",  // When the user clicks the mouse
  "click",      // When the user clicks anywhere on the page
  "scroll",     // When the user scrolls the page
  "keypress",   // When the user presses any key on the keyboard
];

const AutoLogout = ({ children }) => {
  let timer;  // Timer variable for tracking inactivity duration
  const navigate = useNavigate();  // Hook to navigate programmatically

  // useEffect to attach event listeners for the defined events on component mount
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();   // Reset the inactivity timer whenever an event is detected
        handleTimer();  // Start the logout timer
      });
    });
  }, []);

  // Function to clear/reset the inactivity timer
  const resetTimer = () => {
    if (timer) clearTimeout(timer); // Clear the existing timeout if it exists
  };

  // Function to start the timer; if the user is inactive for 20 minutes (1200000ms), log them out
  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();  // Clear the timer before logging out
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer); // Remove event listeners after logout
      });
      logoutAction();  // Perform logout action
    }, 1200000); // Set the inactivity period to 20 minutes (1200000ms)
  };

  // Function to clear the local storage and navigate to the login screen
  const logoutAction = () => {
    localStorage.clear();  // Clear local storage (user session data)
    navigate("/");  // Redirect the user to the login page (or homepage)
    // window.location.pathname = "/"; // Alternative way to navigate (uncomment if needed)
  };

  return children; // Return the children components, so the AutoLogout wraps around them
};

export default AutoLogout;
