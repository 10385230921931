import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    Insurance : {}
  };
  

export const saveInsurance = createAsyncThunk("saveInsurance", (body) =>
    httpClientJava
    .post(`medication/addInsurance`, body)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);


export const save_insurance = createSlice({
    name: "save_insurance",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setSaveInsuranceData: (state, action) => {
        state.Insurance = action.payload;
      },
        [saveInsurance.pending.type]: (state, action) => {
          state.Insurance = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [saveInsurance.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.Insurance = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [saveInsurance.rejected.type]: (state, action) => {
          state.Insurance = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setSaveInsuranceData } = save_insurance.actions;

export default save_insurance.reducer;