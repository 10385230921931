
export const NPIMappingList= (companyName) => {
    if(!companyName) return 
    // Define a mapping of variations to their normalized names
    const NPIMap = {
        "Prisma Health Pharmacy–Baptist": "1275261216",
        "Prisma Health Pharmacy–Center for Family Medicine": "1114325800",
        "Prisma Health Pharmacy–Cross Creek": "1760718761",
        "Prisma Health Pharmacy–Grove Road": "1508816802",
        "Prisma Health Pharmacy–Greer": "1538347893",
        "Prisma Health Pharmacy–Internal Medicine Associates": "1730432964",
        "Prisma Health Pharmacy–Oconee": "1528365046",
        "Prisma Health Pharmacy–Patewood": "1265417927",
        "Prisma Health Pharmacy–Richland": "1821635301",
        "Prisma Health Pharmacy–Stone and Main": "1285332890",
        "Prisma Health Pharmacy–Sumter": "1073146502",
        "Prisma Health Pharmacy–Specialty Pharmacy": "1992212872"
        // Add more mappings as needed
    };

    // Check if the normalized name exists in our map, return the mapped value if found
    if (NPIMap.hasOwnProperty(companyName)) {
        return NPIMap[companyName]?.toLowerCase();
    }
    // If no exact match found, return the original name
    return "";
}
