import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient } from "../environment";
import axios from "axios";

const initialState = {
    costPlusDrugsPrices: []
  };
  
export const fetchCostPlusDrugPrice = createAsyncThunk("DrugDetails", (url) =>
    axios
    .get(url) 
    .then(({data}) => {
        return data;
        // const dispatch = useDispatch();
        // dispatch(setDrugsList(data));
    })
    .catch(error => error)
);


export const drugdetails = createSlice({
    name: "drugdetails",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setCostPlusDrugPrice: (state, action) => {
        state.costPlusDrugsPrices = action.payload;
      },
        [fetchCostPlusDrugPrice.pending.type]: (state, action) => {
          state.drugs = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [fetchCostPlusDrugPrice.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.drugs = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [fetchCostPlusDrugPrice.rejected.type]: (state, action) => {
          state.drugs = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setCostPlusDrugPrice } = drugdetails.actions;

export default drugdetails.reducer;