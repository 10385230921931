
export const mapData = (response) => {

    let names_array = []
    response.Value.map((med, index) => {
        names_array.push(med.Key);
    })

    let genericDrugs = [];

    response.Value.map((drug, i) => {
        drug.Value.map((form, j) => {
            form.Value.map((dosage, k) => {
                dosage.Value.map((quantity, m) => {
                    // debugger
                    if (quantity?.Value?.IsGeneric && genericDrugs.length === 0) {
                        let drugModel = {
                            form: form?.Key,
                            dosage: quantity?.Value?.Dosage,
                            quantity: quantity?.Key,
                            default_quantity: quantity?.Key,
                            name: quantity?.Value?.Name,
                            seoName: quantity?.Value?.SEOName,
                            ndc: quantity?.Value?.NDC,
                            package_size: quantity?.Value?.PackageQuantity,
                            search_name: quantity?.Value?.SEOName,
                            discription: quantity?.Value?.Description,
                            quantities: dosage?.Value?.map((qua, n) => {
                                return qua.Key
                            }),
                            type: "Generic",
                            names: names_array
                        }
                        genericDrugs.push(drugModel)
                    }
                })
            })
        })
    })
    console.log({ genericDrugs })

    let brandedDrug = {
        form: response.Value[0].Value[0].Key,
        dosage: response.Value[0].Value[0].Value[0].Value[0].Value.Dosage,
        quantity: response.Value[0].Value[0].Value[0].Value[0].Value.Quantity,
        name: response.Value[0].Value[0].Value[0].Value[0].Value.Name,
        ndc: response.Value[0].Value[0].Value[0].Value[0].Value.NDC,
        description: response.Value[0].Value[0].Value[0].Value[0].Value.Description,
        package_size: response.Value[0].Value[0].Value[0].Value[0].Value.PackageQuantity,
        search_name: response.Value[0].Value[0].Value[0].Value[0].Value.SEOName,
        default_quantity: response.Value[0].Value[0].Value[0].Value[0].Value.Quantity,
        quantities: response.Value[0].Value[0].Value[0].Value.map((quantity, index) => {
            return quantity.Key;
        }),
        type: "Brand",
        names: names_array
    }
    return genericDrugs.length > 0 ? genericDrugs[0] : brandedDrug;
}