import { URL } from "./urls";
import { httpClientJava } from "../../environment";
import { GoogleAPIKeyClient } from "../../environment";
import axios from "axios";

export const getUserPharmacyList = (userDetail, callback) => {
    httpClientJava.post(URL.getUserPharmaciesList, {appUserSeqNo : userDetail?.appUserSeqNo})
    .then((res)=>{
        if(callback) callback(res)
    })
    .catch((err)=>{
        console.log({err});
    })
}


export const addOrUpdatePharmacy = (userDetail, pharmacy, callback) => {
    httpClientJava.post(URL.postUserPharmacy, {...pharmacy, appUserSeqNo: userDetail?.appUserSeqNo})
    .then((res)=>{
        if(callback) callback(res)
    })
    .catch((err)=>{
        console.log(err);
    })
}


export const removePharmacy = (userDetail, pharmacyNo, callback) => {
    httpClientJava.post(URL.removeUserPharmacy, {userPharmacySeqNo : pharmacyNo, appUserSeqNo: userDetail?.appUserSeqNo})
    .then((res)=>{
        if(callback) callback(res)
    })
    .catch((err)=>{
        console.log(err);
    })

}


export const getZipCodeFromLatLng = async (lat, lng, onSuccess, onError) => {
   await  fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GoogleAPIKeyClient}`)
    .then((response) => response.json())
    .then((data) => {
      const addressComponents = data.results[0].address_components;
      const zipCodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      const zipCode = zipCodeComponent ? zipCodeComponent.long_name : "ZIP code not found";
      onSuccess(zipCode)
    })
    .catch((error) => {
      console.error("Error fetching data from Geocoding API:", error);
      onError({zipCode: undefined});
    });
}

export const getLatLngFromAddress = async (address, onSuccess, onError) => {
    const encodedAddress = encodeURIComponent(address);

    await  fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&sensor=false&key=${GoogleAPIKeyClient}`)
     .then((response) => response.json())
     .then((data) => {
    //    const addressComponents = data.results[0].address_components;
    //    const zipCodeComponent = addressComponents.find((component) =>
    //      component.types.includes("postal_code")
    //    );
    //    const zipCode = zipCodeComponent ? zipCodeComponent.long_name : "ZIP code not found";
       onSuccess(data)
     })
     .catch((error) => {
       console.error("Error fetching data from Geocoding API:", error);
       onError({data: undefined});
     });
 }
// export const getNPIRegistry = (zipcode, skip = 0, callback) => {
//     axios.get(`https://npiregistry.cms.hhs.gov/api/?enumeration_type=NPI-2&postal_code=${zipcode}&limit=200&skip=${skip}&pretty=on&version=2.1`)
//     .then((res)=>{
//         if(callback) callback(res)
//     })
//     .catch((err)=>{
//         console.log(err);
//     })
// }

export const getNPIRegistry = (zipcode, skip = 0, callback, errorCallback) => {
    httpClientJava.post(URL.getNPI, {
        enumeration_type:"NPI-2",
        postal_code:zipcode,
        limit:"200",
        skip:`${skip}`
    })
    .then((res)=>{
        if(callback) callback(JSON.parse(res.data.data))
    })
    .catch((err)=>{
        console.log(err);
        if(errorCallback) errorCallback()
    })
}



