import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    removeMedicationResponse : {}
  };
  
export const deleteMedication = createAsyncThunk("removeMedication", (body) =>
httpClientJava
    .post(`medication/remove`, body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);


export const removeMedicationData = createSlice({
    name: "removeMedication",
    initialState,
    reducers: {
      setremoveMedicationResponse: (state, action) => {
        state.removeMedicationResponse = action.payload;
      },
        [deleteMedication.pending.type]: (state, action) => {
          state.removeMedicationResponse = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [deleteMedication.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.removeMedicationResponse = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [deleteMedication.rejected.type]: (state, action) => {
          state.removeMedicationResponse = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setremoveMedicationResponse } = removeMedicationData.actions;

export default removeMedicationData.reducer;