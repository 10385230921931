import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient, httpClientJava } from "../environment";

const initialState = {
    careCard: [],
    careCardSave: {}
  };
  
export const fetchCareCardInfo = createAsyncThunk("CareCard", (body) =>
    httpClientJava
    .post('rxSense/getCareCardInfo', body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

export const saveCareCardInfo = createAsyncThunk("saveCareCard", (body) =>
  httpClientJava
  .post('rxSense/saveCareCardInfo', body) 
  .then(({data}) => {
      return data;
  })
  .catch(error => error)
);


export const careCardDetails = createSlice({
    name: "careCarddetails",
    initialState,
    reducers: {
      setCareCardDetails: (state, action) => {
        state.careCard = action.payload;
      },
      setSaveCareCardInfo: (state, action) => {
        state.careCardSave = action.payload;
      },
      },
});

export const { setCareCardDetails, setSaveCareCardInfo } = careCardDetails.actions;

export default careCardDetails.reducer;