import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    insuranceInfo : {}
  };
  
export const fetchInsuranceInfo = createAsyncThunk("Insurance", (body) =>
httpClientJava
    .post(`medication/getInsurances`, body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);


export const insuranceInfoDetail = createSlice({
    name: "Insurance",
    initialState,
    reducers: {
      setInsuranceInfo: (state, action) => {
        state.insuranceInfo = action.payload;
      },
        [fetchInsuranceInfo.pending.type]: (state, action) => {
          state.insuranceInfo = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [fetchInsuranceInfo.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.insuranceInfo = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [fetchInsuranceInfo.rejected.type]: (state, action) => {
          state.insuranceInfo = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setInsuranceInfo } = insuranceInfoDetail.actions;

export default insuranceInfoDetail.reducer;