import React, { useEffect } from 'react'
import ariaAnnounce from '../utilities/useAnnounceText'

function LoadingComponent() {

    useEffect(() => {
        ariaAnnounce("please wait", "loaderId");
        document.getElementById('loaderId').focus()
    }, [])

    return (
        <div className="loader" id="loaderId" alt="Please wait" tabIndex={0}></div>
    )
}

export default LoadingComponent