
export const normalizeCompanyName= (companyName) => {
    if(!companyName) return 
    // Define a mapping of variations to their normalized names
    const companyMap = {
        "WALGREENS": "WALGREENS",
        "WALGREENS PHARMACY": "WALGREENS",
        "WALGREEN PHARMACY": "WALGREENS",
        "WALGREENS CO": "WALGREENS",
        "WALGREEN CO": "WALGREENS",
        "WALGREEN": "WALGREENS",
        "WAL-MART": "WALMART",
        "WALMART": "WALMART",
        "WALMART PHARMACY": "WALMART",
        "CVS PHARMACY": "CVS",
        "CVS INSIDE TARGET": "CVS",
        "GEORGIA CVS PHARMACY, L.L.C.": "CVS",
        "GEORGIA CVS PHARMACY L.L.C.": "CVS",
        "GEORGIA CVS PHARMACY LLC": "CVS",
        "KROGER PHARMACY": "KROGER",
        "H-E-B": "HEB",
        "HEB PHARMACY": "HEB",
        "COSTCO PHARMACY": "COSTCO",
        "ALBERTSON PHARMACY": "ALBERTSON",
        "HARRIS DRUG STORE": "HARRIS DRUG",
        "SAFEWAY PHARMACY" : "SAFEWAY",
        "RITE AID PHARMACY": "RITE AID",
        "RITE_AID": "RITE AID",
        "PUBLIX PHARMACY": "PUBLIX",
        "WALMART NEIGHBORHOOD MARKET": "WALMART",
        "WASHINGTON CVS PHARMACY LLC": "CVS",
        // Add more mappings as needed
    };

    // Convert input to uppercase for case-insensitive matching
    let normalized = companyName.toUpperCase();

    // Check if the normalized name exists in our map, return the mapped value if found
    if (companyMap.hasOwnProperty(normalized)) {
        return companyMap[normalized]?.toLowerCase();
    }
    // If no exact match found, return the original name
    return companyName.toLowerCase();
}
