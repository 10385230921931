import { useState, useEffect } from 'react';

export const useStyleMediaQuery = ({ mixOrMax = 'min', widthOrHeight = 'width', value }) => {
  const mediaQuery = `(${mixOrMax}-${widthOrHeight}: ${value}px)`;
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    
    const listener = (e) => setMatches(e.matches);
    
    // For modern browsers
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener);
    } else {
      // For older browsers
      mediaQueryList.addListener(listener);
    }
    
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', listener);
      } else {
        mediaQueryList.removeListener(listener);
      }
    };
  }, [mixOrMax, widthOrHeight, value, mediaQuery]);

  return { matches };
};