import { useEffect, useState, useRef } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSignUp } from "../../reducers/signUp";
import 'react-toastify/dist/ReactToastify.css';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MotionText } from "../motion/MotionText";
import { errorToast } from "../toast/toast";
import { usernameValidation } from "../Fields/Username";
import { PasswordRegex } from "../../utilities/contants";
import { StringToHTML } from "../../utilities/StringToHtml";
import { getDomainName } from "../../utilities/useGetDomain";
import TickCross from "../../utilities/TickCross";
import { ariaAnnounce } from "../../utilities/useAnnounceText";

const SignUp = (props) => {
  const navigate = useNavigate(); // For page navigation
  const dispatch = useDispatch(); // For dispatching Redux actions
  const [loading, setLoading] = useState(false); // Loading state to prevent multiple submissions
  const [passType, setPassType] = useState("password"); // State to toggle password visibility
  const [conPassType, setConPassType] = useState("password"); // State to toggle confirm password visibility

  // Refs to manage focus on inputs during validation
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const usernameRef = useRef(null);

  useEffect(() => {
    ariaAnnounce("personalized medication savings"); // Effect to announce text for screen readers when the component loads
  }, [])

  var numberPattern = /\d+/g; // Regular expression to extract only numbers (used for phone number)

  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    username: usernameValidation,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        PasswordRegex,
        'Password must be 8 characters long and contain a mix of letters and numbers.'
      ),
    verifyPassword: Yup.string()
      .required("Verify password is required")
      .oneOf([Yup.ref('password')], 'Passwords do not match')
  });

  // Get domain details like `pid`, `domainName`, `defaultLogo`, `altText` from a utility
  const { pid, domainName, defaultLogo, altText } = getDomainName();

  // Formik setup for managing form state and validation
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      verifyPassword: "",
    },
    validationSchema,
    onSubmit: (data) => {
      // Prevent form submission if already loading
      if (loading) return;
      setLoading(true);
      let body = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        username: data.username.match(numberPattern).join(""), // Extract phone number digits
        password: data?.password,
        pid: pid,
        domainName: domainName
      };
      // Dispatch signup action via Redux
      dispatch(userSignUp(body))
        .unwrap()
        .then((response) => {
          body.password = null;
          localStorage.setItem("userBody", JSON.stringify(body));
          setLoading(false);
          // Check for success status and navigate to OTP verification if successful
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            navigate("/otpVerify");
          } else {
            // Show error message via toast if signup fails
            errorToast(<StringToHTML htmlString={response?.message} />);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    },
  });

  const { isSubmitting, isValidating } = formik; // Track Formik's submitting and validating state

  // Effect to set focus on the first input with an error during form submission
  useEffect(() => {
    if (isSubmitting && isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const firstErrorField = Object.keys(formik.errors).find(
          (fieldName) => formik.touched[fieldName]
        );
        // Set focus to the first error field
        if (firstErrorField) {
          switch (firstErrorField) {
            case "firstName":
              firstNameRef.current && firstNameRef.current.focus();
              break;
            case "lastName":
              lastNameRef.current && lastNameRef.current.focus();
              break;
            case "password":
              newPasswordRef.current && newPasswordRef.current.focus();
              ariaAnnounce("Password must be 8 characters long and contain a mix of letters and numbers.")
              break;
            case "username":
              window.document.getElementById("signupPhoneInput").focus();
              ariaAnnounce(formik.errors.username)
              break;
            case "verifyPassword":
              confirmPasswordRef.current && confirmPasswordRef.current.focus();
              setTimeout(() => {
                ariaAnnounce(formik.errors.verifyPassword)
              }, 200)
              break;
            default:
              break;
          }
        }
      }
    }

  }, [isSubmitting, isValidating]);

  const signIn = (e) => {
    navigate("/");
  };

  //Animations 
  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  // Trigger animation when component comes into view
  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  // Function to toggle password visibility
  const changePassType = () => {
    if (passType == "password") {
      setPassType("text")
    }
    else {
      setPassType("password")
    }
  }
  // Function to toggle confirm password visibility
  const changeConPassType = () => {
    if (conPassType == "password") {
      setConPassType("text")
    }
    else {
      setConPassType("password")
    }
  }

  // Effect to announce text in the live region for screen readers
  useEffect(() => {
    const liveRegion = document.getElementById('headingToAnnounce');
    if (liveRegion) {
      liveRegion.innerText = 'Personalized medication savings';
      liveRegion.setAttribute('aria-live', 'assertive');
    }
  }, [])

  return (
    <>
      <section className="">
        <div className="login_section" role="main">
          <motion.form onSubmit={formik.handleSubmit} id="signUpForm"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="row g-0">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="login_section_inner">
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="logo">
                        <motion.img
                          src={defaultLogo}
                          className="img-fluid"
                          alt={altText}
                          initial={{ y: -100 }}
                          animate={{ y: 0 }}
                          transition={{ duration: 0.5, delay: 0.4 }}
                        />
                      </div>
                      <MotionText text={'Personalized medication savings'}
                        ctrls={ctrls}
                        wordAnimation={wordAnimation}
                        characterAnimation={characterAnimation}
                        cref={ref}
                        id="headingToAnnounce"
                      />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1 form-group">
                        <input
                          name="firstName"
                          type="text"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.firstName && formik.touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                          id="signupFirstNameInput"
                          placeholder="Enter your first name"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          aria-label="First Name"
                          ref={firstNameRef}

                        />
                        <div className="invalid-feedback">
                          {formik.errors.firstName && formik.touched.firstName
                            ? formik.errors.firstName
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1  form-group">
                        <input
                          name="lastName"
                          type="text"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.lastName && formik.touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                          id="signupLastNameInput"
                          placeholder="Enter your last name"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          aria-label="Last Name"
                          ref={lastNameRef}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.lastName && formik.touched.lastName
                            ? formik.errors.lastName
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forget_input_eye">
                    <InputMask
                      name='username'
                      className={`form-control  custom-form-control ${true && "disabled-input"}` +
                        (formik.errors.username && formik.touched.username
                          ? " is-invalid"
                          : "")}
                      mask="(999) 999-9999"
                      maskChar={null}
                      placeholder="Mobile phone number"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      aria-label="Mobile Phone Number"
                      inputRef={usernameRef}
                      id="signupPhoneInput"
                    >
                    </InputMask>
                    {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
                      {formik.errors.username && formik.touched.username
                        ? formik.errors.username
                        : null}
                    </div>}
                    {TickCross(formik, "username", "error-circle")}
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1 forget_input_eye form-group">
                        <input
                          name="password"
                          type={passType}
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.password && formik.touched.password
                              ? " is-invalid"
                              : "")
                          }
                          id="signupPasswordInput"
                          placeholder="Enter your password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          autoComplete="new-password"
                          ref={newPasswordRef}
                          aria-required="true"
                          aria-describedby="passwordInput"
                          aria-label="Password"
                          onFocus={() => {
                            ariaAnnounce("Password must be 8 characters long and contain a mix of letters and numbers.")
                          }}
                        />
                        <i><a href="javascript:void(0)" aria-label={passType == "password" ? "show Password" : "hide password"}
                          className={passType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changePassType()}
                          style={{ top: 13, color: "#77aca2" }} ></a></i>
                        {TickCross(formik, "password", "error-circle")}
                        {true &&
                          <div className="warn-feedback" id="passwordInput" aria-live="off" style={{ color: formik.errors.password && formik.touched.password ? "red" : "", textAlign: "left" }}>
                            Password must be 8 characters long and contain a mix of letters and numbers.
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1 forget_input_eye form-group">
                        <input
                          name="verifyPassword"
                          type={conPassType}
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.verifyPassword && formik.touched.verifyPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Verify your password"
                          id="signupConfirmPasswordInput"
                          onChange={formik.handleChange}
                          value={formik.values.verifyPassword}
                          autoComplete="new-password"
                          ref={confirmPasswordRef}
                          onFocus={() => setTimeout(() => {
                            ariaAnnounce(formik.errors.verifyPassword)
                          }, 200)}
                          aria-label="Confirm Password"
                        />
                        <i><a href="javascript:void(0)"
                          className={conPassType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeConPassType()}
                          style={{ top: 13, color: "#77aca2" }}
                          aria-label={conPassType == "password" ? "show Password" : "hide password"}></a></i>
                        <div className="invalid-feedback" aria-live="off" id="verifyPasswordError">
                          {formik.errors.verifyPassword && formik.touched.verifyPassword
                            ? formik.errors.verifyPassword
                            : null}
                        </div>
                        {TickCross(formik, "verifyPassword", "error-circle")}
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p className="password pointer">
                        <a href="javascript:void(0)" onClick={() => signIn()} className="txtDecoration">
                          Already have an account ?{" "}
                          <span
                            className="span_link topSignUp"
                          >
                            <u>Sign In</u>
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12"
                      initial={{ y: 50 }}
                      transition={{ type: "spring", duration: 0.8 }}
                      animate={{ y: 0 }}
                      whileTap={{
                        scale: 0.95,
                        transition: { duration: 0.1 },
                      }}
                    >
                      <button
                        className="btn_success"
                        type="submit"
                        form="signUpForm"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.form>
        </div>
      </section>
    </>
  );
};

export default SignUp;