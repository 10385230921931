import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient, httpClientJava } from "../environment";

const initialState = {
    rxLessCard: [],
    careCardSave: {}
  };
  
export const fetchRxLessCardInfo = createAsyncThunk("CareCard", (body) =>
    httpClientJava
    .post('rxSense/getRxLessCardInfo', body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

export const fetchRxLessDrugPrices = createAsyncThunk("rxLessPrices", (body) =>
    httpClientJava
    .post('rxSense/getRxLessPricesInfo', body) 
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

// export const saveCareCardInfo = createAsyncThunk("saveCareCard", (body) =>
//   httpClientJava
//   .post('rxSense/saveCareCardInfo', body) 
//   .then(({data}) => {
//       return data;
//   })
//   .catch(error => error)
// );


export const rxLessDetails = createSlice({
    name: "rxLessDetails",
    initialState,
    reducers: {
    //   setCareCardDetails: (state, action) => {
    //     state.careCard = action.payload;
    //   },
    //   setSaveCareCardInfo: (state, action) => {
    //     state.careCardSave = action.payload;
    //   },
      },
});

export const { } = rxLessDetails.actions;

export default rxLessDetails.reducer;