import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    SignUpRes : {}
  };
  

export const userSignUp = createAsyncThunk("Sign Up", (body) =>
    httpClientJava
    .post(`signUp`, body)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);


export const SignedUp = createSlice({
    name: "SignedUp",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setUserData: (state, action) => {
        state.SignUpRes = action.payload;
      },
        [userSignUp.pending.type]: (state, action) => {
          state.SignUpRes = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [userSignUp.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.SignUpRes = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [userSignUp.rejected.type]: (state, action) => {
          state.SignUpRes = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setUserData } = SignedUp.actions;

export default SignedUp.reducer;