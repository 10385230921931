import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import TickCross from "../../utilities/TickCross";
import {ariaAnnounce} from "../../utilities/useAnnounceText";

const phoneNumberMask = "(999) 999-9999"

export const UsernameField = ({ formik, mask, setMask, id, ariaLabel, inputRef }) => {
    const [username, setUsername] = useState(formik.values.username);

    useEffect(() => {
        const text = formik.values.username.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")
        // if (parseInt(text) && text.length < 11) {
        setMask(phoneNumberMask)
        // } else {
        //     setMask("")
        // }
    }, [formik.values.username])

    useEffect(() => {
        formik.setFieldValue("username", username)
    }, [username])

    useEffect(() => {
        setTimeout(() => formik.validateForm(), 200)
    }, [mask])

    const onUsernameChange = (e) => {
        const key = e.key
        const text = e.target.value
        const replacedText = text.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")
        if (mask) {
            if (key.toLowerCase() === "backspace") return;
            if ((!parseInt(key) && key !== "0") || (replacedText.length > 10)) {
                const addKey = key.length === 1 ? key : ""
                // setMask("")
                setUsername(replacedText + addKey)
            }
            return;
        }
        if (parseInt(replacedText) && replacedText.length < 11) {
            setMask(phoneNumberMask)
            setUsername(text)
        }
    }

    const onInput = (e) => {
        const text = e.target.value.replace(/\D/g, '');
        if (!mask && parseInt(text) && text.length < 11) {
            setMask(phoneNumberMask);
        } else if (mask && text.length > 10) {
            setMask('');
        }
        setUsername(e.target.value);
    };
    const announceErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            liveRegion.innerText = message;
            liveRegion.setAttribute('aria-live', 'assertive');
            // debugger
        }
    };



    const hideErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
            liveRegion.setAttribute('aria-live', 'off');
        }
    };
    return (
        <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="mb-1 form-group forget_input_eye">
                    <InputMask
                        name="username"
                        type="tel"
                        className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.username && formik.touched.username
                                ? " is-invalid"
                                : "")
                        }
                        id={id}
                        mask={mask}
                        maskChar={null}
                        onKeyUp={onUsernameChange}
                        onChange={formik.handleChange}
                        onInput={onInput}
                        value={formik.values.username}
                        placeholder="Mobile phone number"
                        ref={inputRef}
                        aria-label={ariaLabel}
                        // onFocus={() => ariaAnnounce(formik.errors.username)}
                        // onBlur={() => hideErrorMessage('usernameError')}
                    />
                    {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
                        {formik.errors.username && formik.touched.username
                            ? formik.errors.username
                            : null}
                    </div>}
                    {TickCross(formik, "username", "error-circle")}
                </div>
            </div>
        </div>
    )
}

export const usernameValidation =
    Yup.string()
        .required("Phone number is required")
        .test('phone-number-length', 'Invalid phone number', (value, ctx) => {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue == "") {
                return ctx.createError({ message: 'Phone number is required' })
            }
            else {
                return numericValue.length === 10;
            }
        })



// : Yup.string()
//     .required("Phone number / Email address is required")
//     .email("Invalid email address")