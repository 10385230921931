import Spinner from 'react-bootstrap/Spinner';

export const getCrxBestPrice = (sub_element, showLoader) => {
    let priceToAdd = 0;
    let priceToMinus = 0;
    sub_element?.drugs?.map((dr) => {
        if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
            priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
            priceToMinus += dr?.bestPrice;
        }
    })
    let newSum = sub_element?.bestPrice - priceToMinus + priceToAdd;
    if ((typeof newSum !== "number" || !isFinite(newSum)) && showLoader) {
        return <div style={{ display: "inline", zIndex: 1001, marginInline: 8 }}><Spinner animation="border" variant="primary" /></div>
    } 
    return newSum?.toLocaleString(
        undefined,
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
}