
import React, { useState, useEffect } from 'react';

const LogoComponent = ({ name }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let attempt = 2;
    const tryLoadImage = async (type) => {
      const imageUrl = `https://images.rxlink.com/connectiveRxlogos/${name}.${type}`;
      try {
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img.src);
          img.onerror = () => reject();
          img.src = imageUrl;
        });
        setImageUrl(imageUrl);
      } catch (error) {
        if(attempt == 2){
          tryLoadImage('svg');
        }
        if(attempt == 1){
          tryLoadImage('jpg');
        }
        attempt--;
        // Failed to load image
      }
    };

    tryLoadImage('png');
    // tryLoadImage('svg');
    // tryLoadImage('jpg');
  }, [name]);

  if (!imageUrl) {
    console.error(`Logo not found for ${name}`);
    return <div>{name}</div>;
  }

  return (
    <img
      src={imageUrl}
      alt={name}
      style={{ maxWidth: 90, maxHeight: 36 }}
      title={name}
    />
  );
};

export default LogoComponent;