import React from 'react'

function FAQ() {
    return (
        <div className='faq' aria-label='FAQ’s About Using RxLink Coupons'   role='alert'>
            <div>
                <h2 style={{ fontStyle: "italic" }}>
                    FAQ’s About Using RxLink Coupons
                </h2>
            </div>
            <div className ="faqs-item">
                <h2>
                    How do I use an RxLink coupon?
                </h2>
                <p>
                    Simply present the coupon(s) to pharmacy staff when filling or refilling a prescription. You can
                    show the coupon(s) from the Pharmacy Instructions Page or from an SMS or e-mail you
                    received from RxLink or your doctor.
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    What do I do if a pharmacy does not let me use my RxLink coupon?
                </h2>
                <p>
                    If a pharmacy does not honor your discounts, they may not be aware that they have a
                    contractual obligation to apply them. If you are having issues with using your discounts at a
                    particular pharmacy, please contact us at <a href='mailto:support@rxlink.com'>support@rxlink.com</a>
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    How do I use RxLink coupons on my refills?
                </h2>
                <p>
                    Ask the pharmacy to keep your coupon(s) on file with your prescription so you receive the same
                    discount on your refills.
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    Can I share coupons with my friends and family?
                </h2>
                <p>
                    Your coupon(s) can be used by friends and family, at any pharmacy, and for any medication.
                    Coupons can always be used at no charge. The amount of the discount and the net price will
                    vary.
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    Can I use an RxLink coupon with Medicare or Medicaid prescription coverage?
                </h2>
                <p>
                    The coupons cannot be used with Medicare or Medicaid to fill a prescription, but can be used
                    instead of any insurance if the price is better.
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    Can I use an RxLink coupon with commercial insurance?
                </h2>
                <p>
                    Always compare the RxLink discounted price to your insurance co-pay. The RxLink price may
                    be lower. Remember, you can always use RxLink instead of any insurance if your net price is
                    lower by using an RxLink coupon card.
                </p>
            </div>

            <div className ="faqs-item">
                <p>
                    Sometimes, the lowest price available uses a coupon provided by the pharmaceutical
                    manufacturer. These should be used along with your insurance. RxLink notes this when
                    showing you these coupons.
                </p>
            </div>

            <div className ="faqs-item">
                <p>
                    <h2 style={{ display: "inline" }}>Note:</h2> Savings and your estimated price with discounts are based on all data currently available
                    to RxLink, including your insurance and insurance co- pay. The pharmacy will provide the exact
                    discounted pricing available at the time you fill your prescription.
                </p>
            </div>

            <div className ="faqs-item">
                <h2>
                    We are always available to answer any questions and help with use of RxLink coupons
                    by e-mailing <a href='mailto:support@rxlink.com'>support@rxlink.com</a>
                </h2>
            </div>
        </div>
    )
}

export default FAQ