import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
  Profile: {}
};


export const saveProfile = createAsyncThunk("saveProfile", (body) =>
  httpClientJava
    .post(`patient/myAccount`, body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);


export const save_profile = createSlice({
  name: "save_profile",
  initialState,
  // reducers: {

  // },
  reducers: {
    setProfileData: (state, action) => {
      state.Profile = action.payload;
    },
    [saveProfile.pending.type]: (state, action) => {
      state.Profile = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [saveProfile.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.Profile = {
        status: "idle",
        data: action.payload,
        error: {},
      };
    },
    [saveProfile.rejected.type]: (state, action) => {
      state.Profile = {
        status: "idle",
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setProfileData } = save_profile.actions;

export default save_profile.reducer;