import React, { useState, useEffect } from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { useWindowWidth } from './searchPharmacy/useWindowWidth'; // Custom hook to get window width
import { MotionTextHeading, MotionTextPara } from './motion/MotionText'; // Custom components for animated text
import ariaAnnounce from '../utilities/useAnnounceText'; // Accessibility utility for announcing text

// InfoSection component handles animated text display
const InfoSection = () => {
    const { windowWidth } = useWindowWidth(); // Get current window width
    const isTab = windowWidth > 766 && windowWidth <= 992; // Check if screen is tablet size
    const isDesktop = windowWidth > 992; // Check if screen is desktop size

    // State variables for dynamic text that switches every 3 seconds
    const [text1, setText1] = useState('Searching for prescription discounts');
    const [text2, setText2] = useState('discounts up a lot of savings');

    // Effect to alternate the text every 3 seconds
    useEffect(() => {
        const interval1 = setInterval(() => {
            setText1((prevText) => (prevText === 'Searching for prescription discounts' ? 'Checking manufacturer savings offers' : 'Searching for prescription discounts'));
        }, 3000);

        const interval2 = setInterval(() => {
            setText2((prevText) => (prevText === 'discounts up a lot of savings' ? "so much savings we're off'er rockers" : 'discounts up a lot of savings'));
        }, 3000);

        // Cleanup intervals on component unmount
        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
        };
    }, []);

    // Function to define dynamic container style based on screen size
    const getContainerStyle = () => {
        let containerStyle = {};

        if (isDesktop) {
            containerStyle = {
                position: "absolute",
                left: 80,
                top: 160,
                zIndex: 1,
                opacity: .8
            }
        }
        else if (isTab) {
            containerStyle = {
                position: "fixed",
                left: "50%",
                top: "20%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "max-content",
                zIndex: 1,
                opacity: .8
            }
        }
        else {
            containerStyle = {
                position: "fixed",
                left: "50%",
                top: "20%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "max-content",
                zIndex: 1,
                opacity: .8
            }
        }
        return containerStyle;
    }

    // Function to define dynamic heading style based on screen size
    const getHeadingStyle = () => {
        let headingStyle = {};
        if (isDesktop) {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 489,
                fontSize: 40
            }
        }
        else if (isTab) {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 520,
                fontSize: 42,
            }
        }
        else {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 300,
                fontSize: 22
            }
        }
        return headingStyle;
    }

    return (
        <div className='info-section-loader'
            style={getContainerStyle()}>
            {/* Animated text heading */}
            <MotionTextHeading text={text1} style={getHeadingStyle()} key={text1} />
            {/* Animated paragraph text */}
            <MotionTextPara text={text2} style={{ fontSize: 14 }} key={text2} />
        </div>
    );
}

const Loader = () => {

    const { windowWidth } = useWindowWidth(); // Get current window width
    const isTab = windowWidth > 766 && windowWidth <= 992; // Check if screen is tablet size
    const isDesktop = windowWidth > 992; // Check if screen is desktop size

    // Function to adjust the margin based on screen size for lottie animation
    const getMarginLeft = () => {
        if (isDesktop) return 200;
        else if (isTab) return -700;
        else return -1380;
    }

    // Accessibility announcement for screen readers when loader is displayed
    useEffect(() => {
        ariaAnnounce("please wait");
    }, []);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            minWidth: '1800px',  // Set a minimum width for the loader to prevent layout shift
            zIndex: 100000,
            background: 'linear-gradient(50deg, rgb(255, 255, 255) 0%, rgb(209 255 243) 100%)'
        }}>
            {/* Information section with animated text */}
            <InfoSection />
            
            {/* Lottie animation player with dynamic margin */}
            <DotLottiePlayer
                src="https://lottie.host/4d4c51a7-31b3-42a4-b8aa-44940598c27d/5hMpldE7g5.json"
                background="transparent"
                speed={1}
                loop
                autoplay
                style={{
                    width: '100%',
                    height: '120%',
                    marginLeft: getMarginLeft()
                }}
            />
        </div>
    );
}

export default Loader;
