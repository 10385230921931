import { toast } from "react-toastify";
import check from "../../assets/images/RxLink assets/new assets/green check.svg";
import redx from "../../assets/images/RxLink assets/new assets/red x.svg";

export const successToast = (message, condition) => {
  if (condition && condition == "showAlert") {
    return toast.success(<span role="none">{message}</span>, {
      role: "none",
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      closeButton: <span className="toast_dismiss">dismiss</span>,
      icon: <img src={check} alt="" />,
    });
  } else {
    return toast.success(<span role="alert">{message}</span>, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      closeButton: <span className="toast_dismiss">dismiss</span>,
      icon: <img src={check} alt="" />,
    });
  }
};

export const successToastWithId = (message, id) => {
  if (id) {
    if (toast.isActive(id)) {
      return;
    }
    else {
      return toast.success(<span role="none">{message}</span>, {
        role: "none",
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
        closeButton: <span className="toast_dismiss">dismiss</span>,
        icon: <img src={check} alt="" />,
        toastId: id
      });
    }
  }
};

export const errorToast = (message, id) => {

  if (id) {
    if (toast.isActive(id)) {
      return;
    }
    else {
      return toast.error(<span role="alert">{message}</span>, {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
        closeButton: <span className="toast_dismiss">dismiss</span>,
        icon: <img src={redx} alt="" />,
        toastId: id
      });
    }

  }
  else {
    return toast.error(<span role="alert">{message}</span>, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      closeButton: <span className="toast_dismiss">dismiss</span>,
      icon: <img src={redx} alt="" />,
    });
  }
};
