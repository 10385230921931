// import { pharmacyTypes } from "./PharmacyTypes";
export function filterPharmacyNames(selectedPharmacy, pharmacyTypes) {
    const regex = new RegExp('pharmacy', 'gi');
    var pharmacyNameWithoutPostfix = selectedPharmacy;
    //selectedPharmacy.replace(/Pharmacy$/i, "").trim();
    if (pharmacyNameWithoutPostfix?.toLowerCase() == "h-e-b") pharmacyNameWithoutPostfix = "HEB";
    const isMatchingType = pharmacyTypes?.some((pharmacyType) => pharmacyNameWithoutPostfix?.toLowerCase() == pharmacyType?.toLowerCase()
        // pharmacyNameWithoutPostfix?.toLowerCase()?.includes(pharmacyType?.toLowerCase())
    );
    if (isMatchingType) {
        return { name: pharmacyNameWithoutPostfix, type: pharmacyNameWithoutPostfix };
    } else {
        const nameIncludes = pharmacyTypes?.filter((type)=> type?.toLowerCase()?.slice(0, -1) == pharmacyNameWithoutPostfix?.toLowerCase());
        if(nameIncludes.length > 0){
            return { name: nameIncludes[0], type: nameIncludes[0] };
        }
        return { name: pharmacyNameWithoutPostfix, type: "Local Pharmacies" };
    }
}