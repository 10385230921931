import { motion } from "framer-motion";

export const MotionText = ({
    text,
    delay = 0.42,
    duration = 0.05,
    ...props
}) => {
    // const letters = Array.from(text);
    const letters = text.split(' ');

    const container = {
        hidden: {
            opacity: 0.1
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: duration, delayChildren: i * delay }
        })
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        },
        hidden: {
            opacity: 0.3,
            y: 20,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        }
    };
    return (
        <motion.h1
            variants={container}
            initial="hidden"
            animate="visible"
            {...props}
            // aria-label={text}
            // title={text}
            // role="heading"
            id={props.id}
            aria-live="off"
        >
            {letters.map((letter, index) => (
                <motion.span key={index} variants={child} style={{ marginRight:"4px" }}>
                    {letter === " " ? "\u00A0" : letter}
                </motion.span>
            ))}
        </motion.h1>
    )
}


export const MotionTextH2 = ({
    text,
    delay = 0.4,
    duration = 0.05,
    ...props
}) => {
    // const letters = Array.from(text);
    const letters = text.split(' ');
    const container = {
        hidden: {
            opacity: 0
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: duration, delayChildren: i * delay }
        })
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        },
        hidden: {
            opacity: 0,
            y: 20,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        }
    };
    return (
        <motion.h1
            variants={container}
            initial="hidden"
            animate="visible"
            {...props}
            role="heading"
        >
            {letters.map((letter, index) => (
                <motion.span style={{ marginRight:"4px" }} key={index} variants={child} aria-live="polite">
                    {letter === " " ? "\u00A0" : letter}
                </motion.span>
            ))}
        </motion.h1>
    )
}

export const MotionTextH1 = ({
    text,
    delay = 0.4,
    duration = 0.05,
    ...props
}) => {
    // const letters = Array.from(text);
    const letters = text.split(' ');
    const container = {
        hidden: {
            opacity: 0
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: duration, delayChildren: i * delay }
        })
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        },
        hidden: {
            opacity: 0,
            y: 20,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        }
    };
    return (
        <motion.h1
            variants={container}
            initial="hidden"
            animate="visible"
            {...props}
            aria-hidden="true"
        >
            {letters.map((letter, index) => (
                <motion.span style={{ marginRight:"4px" }} key={index} variants={child}>
                    {letter === " " ? "\u00A0" : letter}
                </motion.span>
            ))}
        </motion.h1>
    )
}



export const MotionTextHeading = ({
    text,
    delay = 0.1,
    duration = 0.4,
    ...props
}) => {
    // const letters = Array.from(text);
    const letters = text.split(' ');
    const container = {
        hidden: {
            opacity: 0
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: {  duration: duration ,delay: delay }
        })
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        },
        hidden: {
            opacity: 0,
            y: 20,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        }
    };
    return (
        <motion.h1
            variants={container}
            initial="hidden"
            animate="visible"
            {...props}
            aria-hidden="true"        >
            {/* {letters.map((letter, index) => (
                <motion.div style={{ marginRight:"4px" }} key={index} variants={child} aria-live="polite">
                    {letter === " " ? "\u00A0" : letter}
                </motion.div>
            ))} */}
            {text}
        </motion.h1>
    )
}

export const MotionTextPara = ({
    text,
    delay = 0.2,
    duration = 0.4,
    ...props
}) => {
    // const letters = Array.from(text);
    const letters = text.split(' ');
    const container = {
        hidden: {
            opacity: 0
        },
        visible: (i = 1) => ({
            opacity: 1,
            transition: {  duration: duration ,delay: delay }
        })
    };

    const child = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        },
        hidden: {
            opacity: 0,
            y: 20,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 200
            }
        }
    };
    return (
        <motion.p
            variants={container}
            initial="hidden"
            animate="visible"
            {...props}
            aria-hidden="true"        >
            {/* {letters.map((letter, index) => (
                <motion.div style={{ marginRight:"4px" }} key={index} variants={child} aria-live="polite">
                    {letter === " " ? "\u00A0" : letter}
                </motion.div>
            ))} */}
            {text}
        </motion.p>
    )
}



