import { getNPIRegistry } from "../components/searchPharmacy/api";

export const filterNPIResults = (npiData, name) => {
    console.log({pharmaName: name})
    if(npiData.length > 0)
    return npiData?.filter(i => {
        const orgName = i?.basic?.organization_name?.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '');
        const parentOrgName = i?.basic?.parent_organization_legal_business_name?.toLowerCase();
        
        if (orgName?.includes(name?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]/g, ''))) {
            return true;
        } else if (i?.basic?.organizational_subpart === "YES" && parentOrgName?.includes(name?.toLowerCase())) {
            return true;
        }
        return false;
    });
    else{
        return []
    }
};

export const getNPIByName = async (name, postalCode, skip = 0, callback) => {
    const MAX_ATTEMPTS = 3;
    const searchNPI = async (attempt) => {
        if (attempt <= MAX_ATTEMPTS) {
            await getNPIRegistry(postalCode, skip, (npiData) => {
                const filteredNameItem = filterNPIResults(npiData, name != "Local Pharmacies" ? name : "" );
                console.log({filteredNameItem})

                if (filteredNameItem.length > 0) {
                    const npi = filteredNameItem[0].number;
                    callback(npi);
                } else if (skip < 400 && npiData.result_count == 200) {
                    skip += 200;
                    searchNPI(attempt + 1);
                   
                } else {
                    let randomNPI = npiData?.results.length > 0 ? npiData?.results[0].number : "1184711087";
                    callback(randomNPI);
                }
            });
        } else {
            callback("");
        }
    };

   await searchNPI(1);
};