import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { httpClient, httpClientJava } from "../environment";
import { act } from "react-dom/test-utils";

const initialState = {
  medication: {},
  showPharmacyMap: false,
  fromDrawer: false,
  pharmacyList: [],
  fromCoupon: false,
  isOpenResetPasswordModal: false,
  fromSearchMap: false,
  fromCouponCard: false,
  savedNpi: "",
  triggerModelFromCoupon: false,
  savingHistory: null,
  couponData: null,
  showCoupon: false,
  pencilClick: false,
  showResetPasswordModal: false,
  pharmaInfo: {},
};

export const fetchMedication = createAsyncThunk("Medication", (name) =>
  httpClient
    .get(`medications/${name}`)
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);
export const fetchMedicationDetail = createAsyncThunk("Medication", (body) =>
  httpClientJava
    .post(`rxSense/GetRxSenseDrugInfo`, {
      drugName: body.name,
      appUserSeqNo: body.appUserSeqNo
    })
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);
export const fetchSavingHistory = createAsyncThunk("savingHistory", (userSeq) =>
  httpClientJava
    .post(`medication/GetTotalHistoricalSavings`, {
      appUserSeqNo: userSeq
    })
    .then((data) => {
      return data;
    })
    .catch(error => error)
);


export const medicationDetail = createSlice({
  name: "Medication",
  initialState,
  reducers: {
    setMedication: (state, action) => {
      state.medication = action.payload;
    },
    setFromDrawer: (state, action) => {
      state.fromDrawer = action.payload;
    },
    setPharmaInfo: (state, action) => {
      state.pharmaInfo = action.payload;
    },
    setShowResetPasswordModal: (state, action) => {
      state.showResetPasswordModal = action.payload;
    },
    setShowPahrmacyMap: (state, action) => {
      state.showPharmacyMap = action.payload;
    },
    setSavedNpi: (state, action) => {
      state.savedNpi = action.payload;
    },
    setFromSearchMap: (state, action) => {
      state.fromSearchMap = action.payload;
    },
    setFromCoupon: (state, action) => {
      state.fromCoupon = action.payload;
    },
    setTriggerModelFromCoupon: (state, action) => {
      state.triggerModelFromCoupon = action.payload;
    },
    setPharmacyList: (state, action) => {
      state.pharmacyList = [].concat(...action.payload)
    },
    setResetPwdModal: (state, action) => {
      state.isOpenResetPasswordModal = action.payload
    },
    setSavingsHistory: (state, action) => {
      state.savingHistory = action.payload
    },
    setCouponData: (state, action) => {
      state.couponData = action.payload
    },
    setShowCoupon: (state, action) => {
      state.showCoupon = action.payload
    },
    setPencilClick: (state, action) => {
      state.pencilClick = action.payload
    },
    [fetchMedication.pending.type]: (state, action) => {
      state.drugs = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [fetchMedication.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.drugs = {
        status: "idle",
        data: action.payload,
        error: {},
      };
    },
    [fetchMedication.rejected.type]: (state, action) => {
      state.drugs = {
        status: "idle",
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setMedication, setShowPahrmacyMap, setPharmacyList, setFromCoupon, setTriggerModelFromCoupon, setFromSearchMap, setSavedNpi, setResetPwdModal, setSavingsHistory, setCouponData, setShowCoupon, setPencilClick, setFromDrawer, setShowResetPasswordModal, setPharmaInfo } = medicationDetail.actions;

export default medicationDetail.reducer;
