import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";


const getParamFromURL = (paramName) => {
  const url = window.location.href;
  const paramStart = url.indexOf(`?${paramName}=`);
  if (paramStart === -1) {
    return null; // Parameter not found in the URL
  }

  const paramEnd = url.indexOf('&', paramStart);
  const valueStart = paramStart + paramName.length + 2; // Add 2 for the "=" character

  if (paramEnd === -1) {
    return url.substring(valueStart);
  } else {
    return url.substring(valueStart, paramEnd);
  }
}

var patientHash = getParamFromURL("p");

const initialState = {
    LoggedInStatus : {},
  };
  

export const login = createAsyncThunk("Login", (body) =>
    httpClientJava
    .post(`patient/login`, body)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

const url = `patient/getUIFlags?urlHash=${patientHash}`;

export const getUIFlags = createAsyncThunk("uiFlag", () =>
    httpClientJava
    .get(url)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

export const loggedIn = createSlice({
    name: "loggedIn",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      
      setUserData: (state, action) => {
        state.LoggedInStatus = action.payload;
      },
        [login.pending.type]: (state, action) => {
          state.LoggedInStatus = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [login.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.LoggedInStatus = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [login.rejected.type]: (state, action) => {
          state.LoggedInStatus = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setUserData } = loggedIn.actions;

export default loggedIn.reducer;