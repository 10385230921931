import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  validateOTP,   // Action to validate OTP
  generateOtp,   // Action to generate OTP
  otpExpiredApi, // Action to handle OTP expiration
} from "../../reducers/validateOtp";
import Vector from "../../assets/images/tab-otp.svg"; // OTP vector image
import { motion } from "framer-motion"; // Animation library
import { successToast, errorToast, successToastWithId } from "../toast/toast"; // Toast notifications
import { isAndroid } from "react-device-detect"; // Device detection for Android
import { StringToHTML } from "../../utilities/StringToHtml"; // Utility function to convert string to HTML
import cross from "../../assets/images/cross.png"; // Image for error icon
import tick from "../../assets/images/check.png"; // Image for success icon

export default ({ setIp }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [inputOTP, setInputOTP] = useState(""); // State for OTP input
  const [ssn_1, setSsn_1] = useState(""); // Individual digits for OTP
  const [ssn_2, setSsn_2] = useState("");
  const [ssn_3, setSsn_3] = useState("");
  const [ssn_4, setSsn_4] = useState("");
  const [ssn_5, setSsn_5] = useState("");
  const [ssn_6, setSsn_6] = useState("");

  const [firstLoad, setFirstLoad] = useState(true); // State to track first load
  const [otpError, setOtpError] = useState("**"); // State to track OTP error
  const userBody = JSON.parse(localStorage.getItem("userBody")); // Fetch user data from localStorage

  // Effect to handle OTP expiration and call the expiration API every 10 minutes
  useEffect(() => {
    setInterval(otpExpired, 600000); // Call function every 10 minutes
    setTimeout(function () {
      otpExpired(); // Call once after 10 minutes
    }, 600000);
  }, []);

  // Effect to handle OTP input and validate its length
  useEffect(() => {
    let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;
    if (!firstLoad) {
      setFirstLoad(false);
      if (concatenate.length < 6) {
        setOtpError(true); // Set error if OTP length is less than 6
      }
      if (concatenate.length == 6) {
        setOtpError(false); // Clear error if OTP is valid
      }
    }
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [ssn_1, ssn_2, ssn_3, ssn_4, ssn_5, ssn_6]);

  // Function to handle OTP input change and focus on next field when max length is reached
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    if (name === "ssn-1") {
      setSsn_1(value, () => {
        let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;
      });
    }
    if (name === "ssn-2") {
      setSsn_2(value);
    }
    if (name === "ssn-3") {
      setSsn_3(value);
    }
    if (name === "ssn-4") {
      setSsn_4(value);
    }
    if (name === "ssn-5") {
      setSsn_5(value);
    }
    if (name === "ssn-6") {
      setSsn_6(value);
    }


    const [fieldName, fieldIndex] = name.split("-");
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  // Function to validate the OTP
  const resetOtp = () => {
    setSsn_1("");
    setSsn_2("");
    setSsn_3("");
    setSsn_4("");
    setSsn_5("");
    setSsn_6("");
  };

  const validateOtp = () => {
    if (loading) return; // Prevent multiple submissions while loading
    let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;

    const userBody = JSON.parse(localStorage.getItem("userBody"));
    if (concatenate.length == 0) {
      errorToast("OTP is required", "otp-error");
      setOtpError(true); // Show error if OTP is not entered
      return;
    }
    if (concatenate.length < 6) {
      errorToast("Valid OTP is required", "otp-valid-error");
      setOtpError(true); // Show error if OTP is incomplete
      return;
    }
    let body = {
      username: userBody?.username,
      otp: concatenate,
    };
    setLoading(true); // Set loading state
    dispatch(validateOTP(body))
      .unwrap()
      .then((response) => {
        if (response.statusCode == 200) {
          successToast("OTP validated.");
          localStorage.setItem("user", JSON.stringify(response?.data));
          setIp(response?.data.proxyIpAddress);
          if (response?.data?.hasInsurance) {
            navigate("/medications"); // Navigate to medications page if the user has insurance
            setLoading(false);
          } else {
            setTimeout(() => {
              navigate("/insurance"); // Navigate to insurance page if the user doesn't have insurance
              setLoading(false);
            }, 3000);
          }
        } else {
          errorToast(<StringToHTML htmlString={response?.message} />);
          setSsn_1("");
          setSsn_2("");
          setSsn_3("");
          setSsn_4("");
          setSsn_5("");
          setSsn_6("");
          resetOtp();
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  // Function to resend OTP
  const resendOtp = () => {
    resetOtp(); // Reset OTP fields
    if (loading) return; // Prevent multiple submissions while loading
    setLoading(true);
    let body = {
      username: userBody?.username,
    };
    dispatch(generateOtp(body))
      .unwrap()
      .then((response) => {
        setInputOTP(null);
        setLoading(false);
        if (response.message) {
          successToastWithId(<StringToHTML htmlString={response?.message} />, "resend-otp");
        }
        else if(response?.status === "BANDWIDTH_LIMIT_EXCEEDED"){
          errorToast(<StringToHTML htmlString={"An OTP code was already sent to your device. Please wait 10 minutes and if the code is still not received email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

   // Function to handle OTP expiration
  const otpExpired = () => {
    setLoading(true);
    let body = {
      username: userBody?.username,
    };
    dispatch(otpExpiredApi(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <section className="fadeAni ">
        <div className="row g-0">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="">&nbsp;</div>
          </div>
        </div>
        <div className="row margintop10 g-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 autoMargin">
            <div className="vector-image">
              {/* Display OTP vector image with animation */}
              <motion.img
                key={Vector}
                initial={{ opacity: 0, x: 500 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, type: "tween" }}
                src={Vector}
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-6 paddingZero"
            style={{ zIndex: 100 }}
          >
            <div className="">
              <motion.div
                className="insurance_inner_main"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                {/* OTP form */}
                <form id="otpID">
                  <div className="row" id="cc-info-div">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <motion.div
                        className="insurance_inner"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.7 }}
                      >
                        <div className="row webView">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="page_counter">
                              <motion.h3
                                className="active"
                                initial={{ scale: 0.5 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 1 }}
                              >
                                1
                              </motion.h3>
                              <h4>2</h4>
                              <h4>3</h4>
                            </div>
                          </div>
                        </div>
                        <div role="alert">
                          <motion.div
                            className="row"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.8 }}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                              <h1>Let’s verify your phone number</h1>
                            </div>
                          </motion.div>
                          <motion.div
                            className="row"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.8, delay: 0.15 }}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <p>
                                Please enter the 6 digit code we just sent you
                                via sms. After verification, you’ll be able to
                                use your number to access your account
                              </p>
                            </div>
                          </motion.div>
                        </div>
                        <motion.div
                          className="row"
                          initial={{ x: 300, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.25 }}
                        >
                          <div
                            className={
                              !isAndroid
                                ? "col-12 col-sm-12 col-md-12 col-lg-12"
                                : "col-6 col-sm-6 col-md-6 col-lg-6"
                            }
                          >
                            <div className="numberVerify forget_input_eye">
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder={isAndroid ? "" : "0"}
                                name="ssn-1"
                                value={ssn_1}
                                onChange={(e) => {
                                  if (
                                    e.currentTarget.value.length < 2 ||
                                    isAndroid
                                  )
                                    handleChange(e);
                                }}
                                autocomplete="one-time-code"
                              />
                              {!isAndroid && (
                                <>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    className="form-control  custom-form-control"
                                    placeholder="0"
                                    name="ssn-2"
                                    value={ssn_2}
                                    maxLength={1}
                                    onChange={(e) => {
                                      if (e.currentTarget.value.length < 2)
                                        handleChange(e);
                                    }}
                                    autocomplete="one-time-code"
                                    max={9}
                                  />
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    className="form-control  custom-form-control"
                                    placeholder="0"
                                    name="ssn-3"
                                    value={ssn_3}
                                    maxLength={1}
                                    onChange={(e) => {
                                      if (e.currentTarget.value.length < 2)
                                        handleChange(e);
                                    }}
                                    autocomplete="one-time-code"
                                    max={9}
                                  />
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    className="form-control  custom-form-control"
                                    placeholder="0"
                                    name="ssn-4"
                                    value={ssn_4}
                                    maxLength={1}
                                    onChange={(e) => {
                                      if (e.currentTarget.value.length < 2)
                                        handleChange(e);
                                    }}
                                    autocomplete="one-time-code"
                                    max={9}
                                  />
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    className="form-control  custom-form-control"
                                    placeholder="0"
                                    name="ssn-5"
                                    value={ssn_5}
                                    maxLength={1}
                                    onChange={(e) => {
                                      if (e.currentTarget.value.length < 2)
                                        handleChange(e);
                                    }}
                                    autocomplete="one-time-code"
                                    max={9}
                                  />
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    inputmode="numeric"
                                    className="form-control  custom-form-control"
                                    placeholder="0"
                                    name="ssn-6"
                                    value={ssn_6}
                                    maxLength={1}
                                    max={9}
                                    onChange={(e) => {
                                      if (e.currentTarget.value.length < 2)
                                        handleChange(e);
                                    }}
                                    autocomplete="one-time-code"
                                  />
                                </>
                              )}
                              {otpError && otpError != "**" && (
                                <span
                                  className={
                                    isAndroid
                                      ? "error-circle-dob-mobile"
                                      : "error-circle-dob"
                                  }
                                >
                                  <img src={cross} height={13} width={13} />
                                </span>
                              )}
                              {!otpError && (
                                <span
                                  className={
                                    isAndroid
                                      ? "error-circle-dob-mobile"
                                      : "error-circle-dob"
                                  }
                                >
                                  <img src={tick} height={13} width={13} />
                                </span>
                              )}
                            </div>
                          </div>
                        </motion.div>
                        <motion.div
                          className="button_div  insurance_inner_main_btn mt-3 g-0"
                          id="cc-info-div-smbt"
                          initial={{ x: 300 }}
                          animate={{ x: 0 }}
                          transition={{ duration: 0.7 }}
                        >
                          <motion.div
                            className="margin-right "
                          >
                            <button
                              className="btn_success"
                              type="button"
                              onClick={() => validateOtp()}
                            >
                              Submit
                            </button>
                          </motion.div>
                          <div className="margin-right ">
                            <button
                              className="btn_default"
                              type="button"
                              onClick={() => resendOtp()}
                            >
                              Resend Code
                            </button>
                          </div>
                        </motion.div>
                      </motion.div>
                      <motion.div
                        className="button_div  insurance_inner_main_btn2 g-0"
                        id="cc-info-div-smbt"
                        initial={{ x: 300 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.7 }}
                      >
                        <div className="margin-right ">
                          <button
                            className="btn_success"
                            type="button"
                            onClick={() => validateOtp()}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="margin-right ">
                          <button
                            className="btn_default"
                            type="button"
                            onClick={() => resendOtp()}
                          >
                            Resend Code
                          </button>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="mobileView">
          <div className="row g-0">
            <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
              <div className="dot-main">
                <span className="dot dotActive"></span>
                <span className="dot"></span>
                <span className="dot "></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
