import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    memberInfo: {},
    appleWalletResponse: {}
};

export const appleWalletAPI = createAsyncThunk("appleWalletAPI", (body) =>
    httpClientJava
        .post(`pass/generate`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);



