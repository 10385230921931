const acmeMarkets = "https://images.rxlink.com/AcmeMarkets.webp";
const acme = "https://images.rxlink.com/acme.webp";
const albertsons = "https://images.rxlink.com/Albertsons.webp";
const cityMarket = "https://images.rxlink.com/CityMarket.webp";
const community = "https://images.rxlink.com/Community.webp";
const costco = "https://images.rxlink.com/Costco.webp";
const cvs = "https://images.rxlink.com/CVS.webp";
const dillions = "https://images.rxlink.com/Dillons.webp";
const duaneReade = "https://images.rxlink.com/DuaneReade.webp";
const foodLion = "https://images.rxlink.com/foodlion.webp";
const fredMeyer = "https://images.rxlink.com/FredMeyer.webp";
const giant = "https://images.rxlink.com/giant.webp";
const hannafordBrothers = "https://images.rxlink.com/HannafordBrothers.webp";
const harrisTeeter = "https://images.rxlink.com/harristeeter.webp";
const hyVee = "https://images.rxlink.com/Hy-Vee.webp";
const jewelOsco = "https://images.rxlink.com/Jewel-Osco.webp";
const kingSoopers = "https://images.rxlink.com/kingsoopers.webp";
const kroger = "https://images.rxlink.com/kroger.webp";
const longs = "https://images.rxlink.com/Longs.webp";
const marianos = "https://images.rxlink.com/Marianos.webp";
const meijer = "https://images.rxlink.com/Meijer.webp";
const otherLocal = "https://images.rxlink.com/Otherlocal.webp";
const publix = "https://images.rxlink.com/Publix.webp";
const raleys = "https://images.rxlink.com/Raleys.webp";
const riteAid = "https://images.rxlink.com/RiteAid.webp";
const safeway = "https://images.rxlink.com/safeway.webp";
const shaws = "https://images.rxlink.com/Shaws.webp";
const shopRite = "https://images.rxlink.com/ShopRite.webp";
const smiths = "https://images.rxlink.com/smiths.webp";
const startMarket = "https://images.rxlink.com/StarMarket.webp";
const stopNShop = "https://images.rxlink.com/StopnShop.webp";
const target = "https://images.rxlink.com/Target.webp";
const vons = "https://images.rxlink.com/Vons.webp";
const walgreens = "https://images.rxlink.com/Walgreens.webp";
const walmartNeighborhood = "https://images.rxlink.com/WalmartNeighborhood.webp";
const walmart = "https://images.rxlink.com/Walmart.webp";
const wegmans = "https://images.rxlink.com/Wegmans.webp";
const weis = "https://images.rxlink.com/weis.webp";
const winnDixie = "https://images.rxlink.com/Winn-dixie.webp";


export const searchPharmacyLogo = (pharmacyName, logoUrl, chain = undefined) => {
    if (!pharmacyName) return otherLocal
    const searchPharmacyName = pharmacyName.toLowerCase()
    const searchedPharmacy = logoPharmacies.filter((pharmacy) => pharmacy.pharmacy === searchPharmacyName)
    if (searchedPharmacy.length > 0) return searchedPharmacy[0].logo
    // if(logoUrl) return logoUrl
    if (pharmacyName == "other" || pharmacyName == "Local Pharmacies")
        return otherLocal

    else if (logoUrl)
        return logoUrl
    else return otherLocal
}

export const searchPharmacyType = (pharmacyName) => {
    if (!pharmacyName) return otherLocal
    const searchPharmacyName = pharmacyName.toLowerCase()
    const searchedPharmacy = typePharmacies.filter((pharmacy) => pharmacy.pharmacy === searchPharmacyName)
    if (searchedPharmacy.length > 0) return searchedPharmacy[0]
    else {
        return {
            pharmacy: "local",
            type: "local"
        }
    }
}

const logoPharmacies = [
    {
        pharmacy: "acme markets",
        logo: acmeMarkets
    },
    {
        pharmacy: "acme",
        logo: acme
    },
    {
        pharmacy: "albertsons",
        logo: albertsons
    },
    {
        pharmacy: "city market",
        logo: cityMarket
    },
    {
        pharmacy: "community",
        logo: community
    },
    {
        pharmacy: "costco",
        logo: costco
    },
    {
        pharmacy: "cvs",
        logo: cvs
    },
    {
        pharmacy: "dillions",
        logo: dillions
    },
    {
        pharmacy: "duane reade",
        logo: duaneReade
    },
    {
        pharmacy: "food lion",
        logo: foodLion
    },
    {
        pharmacy: "fred meyer",
        logo: fredMeyer
    },
    {
        pharmacy: "giant",
        logo: giant
    },
    {
        pharmacy: "hannaford brothers",
        logo: hannafordBrothers
    },
    {
        pharmacy: "harris teeter",
        logo: harrisTeeter
    },
    {
        pharmacy: "hy-vee",
        logo: hyVee
    },
    {
        pharmacy: "jewel-osco",
        logo: jewelOsco
    },
    {
        pharmacy: "king soopers",
        logo: kingSoopers
    },
    {
        pharmacy: "kroger",
        logo: kroger
    },
    {
        pharmacy: "longs",
        logo: longs
    }, {
        pharmacy: "marianos",
        logo: marianos
    },
    {
        pharmacy: "meijer",
        logo: meijer
    },
    {
        pharmacy: "other local",
        logo: otherLocal
    },
    {
        pharmacy: "publix",
        logo: publix
    },
    {
        pharmacy: "raleys",
        logo: raleys
    },
    {
        pharmacy: "rite aid",
        logo: riteAid
    },
    {
        pharmacy: "rite",
        logo: riteAid
    },
    {
        pharmacy: "safeway",
        logo: safeway
    },
    {
        pharmacy: "king",
        logo: kingSoopers
    },
    {
        pharmacy: "shaws",
        logo: shaws
    },
    {
        pharmacy: "shoprite",
        logo: shopRite
    },
    {
        pharmacy: "smiths",
        logo: smiths
    },
    {
        pharmacy: "start market",
        logo: startMarket
    },
    {
        pharmacy: "stop n shop",
        logo: stopNShop
    },
    {
        pharmacy: "target",
        logo: target
    },
    {
        pharmacy: "vons",
        logo: vons
    },
    {
        pharmacy: "walgreens",
        logo: walgreens
    },

    {
        pharmacy: "walmart neighborhood",
        logo: walmartNeighborhood
    }, {
        pharmacy: "walmart",
        logo: walmart
    },
    {
        pharmacy: "wegmans",
        logo: wegmans
    },
    {
        pharmacy: "weis",
        logo: weis
    },
    {
        pharmacy: "winn-dixie",
        logo: winnDixie
    }
]


const typePharmacies = [
    {
        pharmacy: "acme markets",
        type: "brand"
    },
    {
        pharmacy: "capsule",
        type: "brand"
    },
    {
        pharmacy: "heb",
        type: "brand"
    },
    {
        pharmacy: "h-e-b",
        type: "brand"
    },
    {
        pharmacy: "acme",
        type: "brand"
    },
    {
        pharmacy: "albertsons",
        type: "brand"
    },
    {
        pharmacy: "city market",
        type: "brand"
    },
    {
        pharmacy: "community",
        type: "brand"
    },
    {
        pharmacy: "costco",
        type: "brand"
    },
    {
        pharmacy: "cvs",
        type: "brand"
    },
    {
        pharmacy: "dillions",
        type: "brand"
    },
    {
        pharmacy: "duane reade",
        type: "brand"
    },
    {
        pharmacy: "food lion",
        type: "brand"
    },
    {
        pharmacy: "fred meyer",
        type: "brand"
    },
    {
        pharmacy: "giant",
        type: "brand"
    },
    {
        pharmacy: "hannaford brothers",
        type: "brand"
    },
    {
        pharmacy: "harris teeter",
        type: "brand"
    },
    {
        pharmacy: "hy-vee",
        type: "brand"
    },
    {
        pharmacy: "jewel-osco",
        type: "brand"
    },
    {
        pharmacy: "king soopers",
        type: "brand"
    },
    {
        pharmacy: "kroger",
        type: "brand"
    },
    {
        pharmacy: "longs",
        type: "brand"
    }, {
        pharmacy: "marianos",
        type: "brand"
    },
    {
        pharmacy: "meijer",
        type: "brand"
    },
    {
        pharmacy: "publix",
        type: "brand"
    },
    {
        pharmacy: "raleys",
        type: "brand"
    },
    {
        pharmacy: "rite aid",
        type: "brand"
    },
    {
        pharmacy: "rite",
        type: "brand"
    },
    {
        pharmacy: "safeway",
        type: "brand"
    },
    {
        pharmacy: "king",
        type: "brand"
    },
    {
        pharmacy: "shaws",
        type: "brand"
    },
    {
        pharmacy: "shoprite",
        type: "brand"
    },
    {
        pharmacy: "smiths",
        type: "brand"
    },
    {
        pharmacy: "start market",
        type: "brand"
    },
    {
        pharmacy: "stop n shop",
        type: "brand"
    },
    {
        pharmacy: "target",
        type: "brand"
    },
    {
        pharmacy: "vons",
        type: "brand"
    },
    {
        pharmacy: "walgreens",
        type: "brand"
    },

    {
        pharmacy: "walmart neighborhood",
        type: "brand"
    }, 
    {
        pharmacy: "walmart",
        type: "brand"
    },
    {
        pharmacy: "wegmans",
        type: "brand"
    },
    {
        pharmacy: "weis",
        type: "brand"
    },
    {
        pharmacy: "winn-dixie",
        type: "brand"
    }
]