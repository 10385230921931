
import { motion } from "framer-motion"
const PageCounter = () => {
    return (
        <div className="webView ">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="page_counter">
                    <h4>1</h4>
                    <h4>2</h4>
                    <motion.h3
                        className="active"
                        initial={{ scale: 0.5 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 1 }}
                    >
                        3
                    </motion.h3>
                </div>
            </div>
        </div>
    )
}
export default PageCounter;