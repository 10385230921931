import Login from "./components/auth/Login";
import SignUp from "./components/auth/Signup";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import InsuranceInfo from "./components/insurance/InsuranceInfo";
import MedicationSearch from "./components/medications/MedicationSearch";
import Savings from "./components/savings";
import NumberVerification from "./components/numberVerification/NumberVerification";
import ForgotTempPassword from "./components/auth/forgotTempPassword";
import MyAccount from "./components/myAccount/MyAccount";
import { successToast, errorToast } from "./components/toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setResetPwdModal } from "./reducers/medication";
import { resetPassword } from "./reducers/resetPassword";
import { StringToHTML } from "./utilities/StringToHtml";
import LoadingComponent from "./components/LoadingComponent";
import { useGetUser } from "./utilities/useGetUser";
import { useNavigate } from "react-router-dom";
import { resetProgressOperation } from "./reducers/global";


// Define an array of routes that require user authentication
const routes = ["/insurance", "/medications", "/myAccount", "/savings"];

const progressBarRoutes = ["/insurance", "/myAccount", "/savings", "/"];


export function RoutesWithAnimation({ ownPosition, setOwnPosition, setIp }) {

  // Get user details using a custom hook
  const userDetail = useGetUser();

  // State to manage loading status
  const [loading, setLoading] = useState(false);

  // Hook for navigation
  const navigate = useNavigate();

  // Initialize Redux dispatch
  const dispatch = useDispatch();

  // Fetch the state for showing or hiding the reset password modal
  const showResetPwdModal = useSelector((state) => state.medication.isOpenResetPasswordModal);

  // Function to close the reset password modal
  const closeChangePasswordModal = () => {
    dispatch(setResetPwdModal(false)); // Dispatch action to close modal
    document.body.style.overflow = 'auto'; // Re-enable body scroll
  }

  // Effect to disable body scroll when the modal is open
  useEffect(() => {
    if (showResetPwdModal) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
    }
  }, [showResetPwdModal]);


  // Function to handle password reset submission
  const submitResetPassword = (submitResetPasswordData, resetForm) => {

    if (loading) return; // Prevent further submission if already loading

    if (submitResetPasswordData?.password) {
      // Prepare reset password payload
      let resetPwd = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword && !submitResetPasswordData?.oldPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };

      // Dispatch the password reset action
      if (resetPwd?.password) {
        setLoading(true); // Start loading spinner
        dispatch(resetPassword(resetPwd))
          .unwrap()
          .then((response) => {
            setLoading(false); // Stop loading spinner
            // Handle success response
            if (response?.statusCode >= 200 && response?.statusCode <= 299) {
              let extendedUser = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem("user", JSON.stringify({
                ...extendedUser,
                token: response.data.token,
                refreshToken: response.data.refreshToken
              }));
              closeChangePasswordModal(); // Close modal
              successToast(response?.message); // Show success message
              if (resetForm) resetForm(); // Reset form
            } else {
              // Handle invalid old password or other errors
              if (response?.statusCode === 401) {
                errorToast("Old password is invalid.");
                if (resetForm) resetForm();
              } else {
                errorToast(<StringToHTML htmlString={response?.message} />);
                if (resetForm) resetForm();
              }
            }
          })
          .catch((e) => {
            setLoading(false); // Stop loading spinner in case of error
            if (resetForm) resetForm(); // Reset form
          });
      }
    }
  }

  // Hook to get the current location (URL path)
  const location = useLocation();

  // Effect to check if the user is authenticated, and if not, redirect to the login page
  useEffect(() => {
    if (!userDetail && routes.includes(location.pathname)) {
      navigate("/") // Redirect to login if user is not authenticated
    }
  }, [location.pathname, userDetail])

  useEffect(() => {
    if (progressBarRoutes.includes(location.pathname)) {
      dispatch(resetProgressOperation("reset"))
    }
  }, [location.pathname])

  return (
    <>
      {loading && (
        <LoadingComponent /> // Show loading spinner if request is in progress
      )}
      <>
        {/* Routes configuration */}
        <Routes key={location.pathname} location={location}>
          <Route exact path="*" element={<Login />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signUp" element={<SignUp />} />
          <Route exact path="/otpVerify" element={<NumberVerification setIp={setIp} />} />
          <Route exact path="/insurance" element={<InsuranceInfo />} />
          <Route exact path="/medications" element={<MedicationSearch ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />} />
          <Route exact path="/myAccount" element={<MyAccount />} />
          <Route exact path="/savings" element={<Savings />} />
        </Routes>

        {/* Reset password modal */}
        {showResetPwdModal &&
          <ForgotTempPassword
            show={showResetPwdModal}
            close={() => closeChangePasswordModal()} // Close modal handler
            setSubmitResetPasswordData={submitResetPassword} // Set submit handler for resetting password
            isOldPassword={true} // Flag indicating this is for old password reset
          />
        }
      </>
    </>
  );
}